<template>
  <div v-if="assessment && selectedSchema">
    <v-container>
      <TitleBar :name="$t('Provider') + ': ' + nameTitle" />

      <v-alert
        v-if="assessment?.messages?.length > 0"
        @click="
          $router.push({
            name: 'MessageIndex',
          })
        "
        border="start"
        class="pointer"
        color="primary"
        elevation="2"
        icon="notification_important"
        prominent
      >
        <v-row class="d-flex align-center">
          <v-col class="grow">
            <span class="fs-18">
              {{ $t('You have a message about this assessment') }}
            </span>
          </v-col>

          <v-col class="ta-right">
            <v-icon
              color="primary"
              icon="east"
              size="30"
            />
          </v-col>
        </v-row>
      </v-alert>

      <v-row
        class="mb-16"
        dense
      >
        <v-col
          cols="12"
          md="3"
        >
          <v-card
            class="mb-4"
            border
            flat
            tile
          >
            <v-card-title class="fs-18 fw-500 bb-1 bc-very-light-grey mb-0">
              <v-row>
                <v-col>
                  {{ $t('Overview') }}
                </v-col>

                <template v-if="assessment.completed_at">
                  <v-col class="d-flex justify-end">
                    <ActionMenu
                      :button-icon-size="18"
                      :items="[
                        {
                          title: 'Download',
                          event: 'download',
                          href: downloadLink(),
                        },
                      ]"
                      button-icon="more_vert"
                      x-small
                    />
                  </v-col>
                </template>
              </v-row>
            </v-card-title>

            <v-list class="v-list--shaped">
              <v-list-item
                :to="{ query: {} }"
                exact
              >
                <v-list-item-title class="d-flex justify-space-between align-center">
                  <h1 class="fs-16 mxw-175 o-dotted">
                    {{ $t(schema.name) }}
                  </h1>

                  <v-icon
                    v-if="assessment.completed_at"
                    color="primary"
                    icon="check_circle"
                  />
                </v-list-item-title>
              </v-list-item>

              <template v-if="worksheets.length > 0">
                <v-list-group :value="true">
                  <template #activator>
                    <v-list-item-title class="fs-6 fw-500">
                      {{ $t('Worksheets') }}
                    </v-list-item-title>
                  </template>

                  <v-list-item
                    v-for="worksheet in worksheets"
                    :key="worksheet.id"
                    :to="{ query: { worksheet_id: worksheet.id } }"
                    lines="two"
                  >
                    <v-list-item-title class="d-flex justify-space-between align-center">
                      <div class="mxw-200">
                        <div class="fs-16 mb-2 o-dotted">
                          {{ $t(worksheet.name) }}
                        </div>

                        <div class="fs-14 c-light-black o-dotted">
                          {{
                            $t(
                              worksheetSchemas.find(
                                (worksheetSchema) => worksheetSchema.id == worksheet.schema_id,
                              ).name,
                            )
                          }}
                        </div>
                      </div>

                      <v-icon
                        v-if="worksheet.completed"
                        color="primary"
                        icon="check_circle"
                      />
                    </v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </template>
            </v-list>
          </v-card>
        </v-col>

        <template v-if="questions.length == 0">
          <v-col
            class="ta-center pa-8"
            cols="12"
            md="9"
          >
            <v-progress-circular
              size="40"
              indeterminate
            />
          </v-col>
        </template>

        <v-col
          cols="12"
          md="9"
        >
          <v-card
            border
            flat
            tile
          >
            <v-card-text>
              <QuestionSet
                v-if="selectedSheet"
                v-model="selectedSheet"
                @change="changed = true"
                @change:attachments="loadAttachments"
                :attachment-owner-id="assessment.id"
                :attachment-owner-type="'Assessment'"
                :attachments="attachments"
                :color="null"
                :divided="$vuetify.display.mdAndUp"
                :hide-field="!fieldsViewable()"
                :landscape="$vuetify.display.mdAndUp"
                :processing="processing"
                :questions="questions"
                :readonly="!!selectedSheet.completed_at"
                :schema="selectedSchema.definition"
                attachment-status
                expanded
                flat
                hide-actions
                inline-verification
                outlined
                tile
                very-dense
              />

              <QuestionSet
                v-else
                v-model="assessment"
                @change="changed = true"
                @change:attachments="loadAttachments"
                :attachment-owner-id="assessment.id"
                :attachment-owner-type="'Assessment'"
                :attachments="attachments"
                :color="null"
                :hide-field="!fieldsViewable()"
                :landscape="$vuetify.display.mdAndUp"
                :processing="processing"
                :questions="questions"
                :readonly="!!assessment.completed_at"
                :schema="selectedSchema.definition"
                attachment-status
                expanded
                flat
                hide-actions
                inline-verification
                outlined
                tile
                very-dense
              />
            </v-card-text>
          </v-card>

          <div class="d-flex align-center mt-4">
            <v-btn
              v-show="changed"
              @click="selectedSheet ? saveWorksheet() : save()"
              :block="$vuetify.display.smallAndDown"
              :loading="processing"
              color="primary"
              size="large"
            >
              {{ $t('Save') }}
            </v-btn>

            <v-spacer />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/manager/services/bright_finder';
import LinkedList from '@/shared/services/linked-list';
import TitleBar from '@/shared/components/TitleBar.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import { useRoute } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import useAssessment from '@/shared/composables/useAssessment';

const eventBus = useEventBus();
const route = useRoute();
const store = useStore();

const attachments = ref([]);
const changed = ref(false);
const schema = ref(null);
const assessment = ref(null);
const documentQuery = ref(null);
const processing = ref(false);
const questions = ref([]);
const rightBarTab = ref(null);
const rightBarVisible = ref(false);
const worksheets = ref([]);
const worksheetSchemas = ref([]);
const selectedSheet = ref(null);
const selectedSchema = ref(null);

const { currentStageId, validateQuestions } = useAssessment(
  attachments,
  assessment,
  documentQuery,
  questions,
  schema,
  selectedSheet,
);

const nameTitle = computed(() => {
  return assessment.value?.provider?.name || assessment.value?.name;
});

watch(rightBarTab, (newValue) => {
  if (newValue != null) {
    rightBarVisible.value = true;
  } else {
    rightBarVisible.value = false;
  }
});

watch(
  () => route.params.assessmentId,
  async () => {
    await load();
  },
  { immediate: true },
);

watch(
  () => route.query.worksheet_id,
  async () => {
    await load();
  },
  { immediate: true },
);

function downloadLink() {
  return Api.manager.assessment.downloadUrl(assessment.value.id, assessment.value.name);
}

async function load() {
  selectedSchema.value = null;
  processing.value = true;
  selectedSheet.value = null;
  questions.value = [];
  await loadAssessment();
  processing.value = false;
}

async function loadAssessment() {
  const response = await Api.manager.assessment.get(route.params.assessmentId);
  assessment.value = response.data;

  currentStageId.value = assessment.value.stage_id;
  schema.value = Object.values(store.state.schemas).find(
    (schema) => schema.id === assessment.value.schema_id,
  );

  worksheetSchemas.value = Object.values(store.state.schemas)
    .filter((schema) => schema.associated_schema_ids.includes(schema.id))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  await loadAttachments();
  await loadWorksheets();
  await loadProvider();

  if (!route.query.worksheet_id && schema.value) {
    selectedSchema.value = schema.value;
    await loadQuestions(schema.value.id);
  }
}

async function loadProvider() {
  const response = await Api.manager.provider.get(assessment.value.provider_id);
  assessment.value.provider = response.data;
}

async function loadAttachments() {
  const response = await Api.manager.attachment.index({
    owner_type: 'Assessment',
    owner_id: assessment.value.id,
  });
  if (response?.status !== 200) return;

  attachments.value = response.data;
}

async function loadQuestions(ownerId, callback) {
  const response = await Api.public_api.organization.question.index({
    owner_id: ownerId,
    owner_type: 'Schema',
  });

  validateQuestions(
    LinkedList.sort(response.data).filter((question) => question.published),
    callback,
  );
}

function draftsAccessible() {
  return schema.value.meta.enable_provider_drafts_access;
}

function fieldsViewable() {
  return draftsAccessible() || assessment.value.completed_at;
}

async function loadWorksheets() {
  const response = await Api.manager.worksheet.index({
    assessment_id: assessment.value.id,
  });
  worksheets.value = response.data
    .filter((worksheet) =>
      worksheetSchemas.value.find((worksheetSchema) => worksheetSchema.id === worksheet.schema_id),
    )
    .sort((a, b) =>
      store.state.schemas[a.schema_id].name > store.state.schemas[b.schema_id].name ? 1 : -1,
    );

  if (route.query.worksheet_id && worksheetSchemas.value.length > 0) {
    selectedSheet.value = worksheets.value.find(
      (worksheet) => worksheet.id === route.query.worksheet_id,
    );

    selectedSchema.value = worksheetSchemas.value.find(
      (worksheetSchema) => worksheetSchema.id === selectedSheet.value.schema_id,
    );

    await loadQuestions(selectedSheet.value.schema_id);
  }
}

async function save() {
  processing.value = true;
  try {
    const response = await Api.manager.assessment.update(assessment.value.id, assessment.value);

    assessment.value = response.data;
    changed.value = false;

    if (assessment.value) {
      await loadQuestions(assessment.value.schema_id, () => {
        eventBus.chime('Saved');
        processing.value = false;
        // assessmentIncomplete.value = getAssessmentIncomplete();
      });
    }
  } catch (error) {
    processing.value = false;
    eventBus.chime(error.response.data.errors[0]);
  }
}

async function saveWorksheet(newValue, reload = false) {
  processing.value = true;
  try {
    const response = await Api.manager.worksheet.update(
      selectedSheet.value.id,
      assessment.value.id,
      newValue || selectedSheet.value,
    );

    if (reload) {
      await load();
    } else {
      selectedSheet.value = response.data;
      changed.value = false;
      this.$set(
        worksheets.value,
        worksheets.value.findIndex((worksheet) => worksheet.id === response.data),
        response.data,
      );
      await loadQuestions(selectedSheet.value.schema_id, () => {
        eventBus.chime('Saved');
        processing.value = false;
        // worksheetIncomplete.value = getWorksheetIncomplete();
      });
    }
  } catch (error) {
    eventBus.chime(error.response.data.errors[0]);
    processing.value = false;
  }
}

onMounted(load);
</script>

<style>
.v-item-group .v-btn--active.theme--light i.v-icon.c-white {
  color: white !important;
}
</style>

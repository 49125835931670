<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card
          v-if="draft"
          class="mb-4"
          border
          tile
        >
          <v-card-title>
            {{ $t('Business information') }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="draft.name"
                @update:model-value="changed = true"
                :aria-label="$t('Business name')"
                :description="
                  $t(
                    'Your business name will be seen when communicating with support staff. If you have multiple locations, we recommend using your registered business name or LLC.',
                  )
                "
                :hard-lock="readonly"
                :message="$t('Business name')"
              />
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="mb-4"
          border
          tile
        >
          <v-card-title class="d-flex align-center">
            {{ $t('Locations') }}

            <v-chip
              class="d-flex ms-3 c-black"
              color="grey-lighten-2"
              inline
            >
              {{ providers.length || 0 }}
            </v-chip>
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-card
              v-for="(provider, index) in providers"
              :key="provider.id"
              :class="{ 'mb-4': index !== providers.length - 1 }"
              border
              tile
            >
              <v-card-text>
                <v-row dense>
                  <v-col class="d-flex align-center">
                    <v-icon
                      class="me-2"
                      color="primary"
                      icon="place"
                    />

                    <div
                      v-text="provider.name"
                      class="fs-16 fw-500"
                    />
                  </v-col>

                  <v-col class="d-flex justify-end align-center">
                    <div
                      v-text="
                        formatAddress(
                          provider?.address1,
                          provider?.city,
                          provider?.state,
                          provider?.zip,
                        )
                      "
                      class="fs-16 fw-500 c-light-black"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <v-card
          v-if="
            store.state.pages.Settings.features &&
            store.state.pages.Settings.features.enable_bank_account &&
            business?.enable_payments
          "
          class="mb-4"
          border
          tile
        >
          <v-card-title>
            {{ $t('Bank account') }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <div class="labeled-input mb-3">
              {{
                $t(
                  'Your banking information is requested in order to deposit funds when you apply for a grant or receive child care payments. This level of permission only allows deposits. We will never withdraw anything from the account.',
                )
              }}
            </div>

            <template v-if="business.bank_account_name">
              <div class="d-flex align-center">
                <v-row class="d-flex align-center">
                  <v-col>
                    <div class="fs-14 c-light-black">
                      {{ $t('Nickname') }}
                    </div>

                    <div
                      v-text="business.bank_account_name || '-'"
                      class="fs-16"
                    />
                  </v-col>

                  <v-col>
                    <div class="fs-14 c-light-black">
                      {{ $t('Type') }}
                    </div>

                    <div
                      v-text="business.bank_account_type || '-'"
                      class="fs-16"
                    />
                  </v-col>

                  <v-col>
                    <div class="fs-14 c-light-black">
                      {{ $t('Routing number') }}
                    </div>

                    <div
                      v-text="business.bank_routing_number || '-'"
                      class="fs-16"
                    />
                  </v-col>

                  <v-col>
                    <div class="fs-14 c-light-black">
                      {{ $t('Account number') }}
                    </div>

                    <div
                      v-text="business.bank_account_number_last_four.toString().padStart(10, 'X')"
                      class="fs-16"
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-btn
              @click="editBankAccount"
              color="primary"
            >
              {{ $t('Update') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="updateBankAccount"
      ref="bankDialog"
      :fields="bankDialogFields"
      :max-width="600"
      :processing="processing"
      :save-button-disabled-function="invalidBankAccount"
      save-button-text="Update"
      title="Update bank account"
      close-on-save
    />

    <SaveBar
      v-model="changed"
      @cancel="cancel"
      @save="updateBusiness"
      :processing="processing || undefined"
    />
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { formatAddress } from '@/shared/services/address';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();

const bankDialog = ref(null);
const bankDialogIsVisible = ref(false);
const bankDialogFields = ref([
  {
    text: 'Routing number',
    description:
      'The 9-digit number on your check identifies your bank and tells us where to find your account.',
    value: 'bank_routing_number',
    mask: '#########',
  },
  {
    text: 'Account number',
    value: 'bank_account_number',
    mask: '####################',
  },
  {
    text: 'Account type',
    value: 'bank_account_type',
    items: ['Checking', 'Savings'],
  },
  {
    text: 'Account nickname',
    description: 'Add a nickname so you can identify your account.',
    value: 'bank_account_name',
  },
]);
const business = ref({});
const changed = ref(false);
const draft = ref(null);
const processing = ref(false);
const profile = ref(null);
const providers = ref([]);

const readonly = computed(() => {
  return store.state.profile.business_role !== 'Admin';
});

function cancel() {
  draft.value = JSON.parse(JSON.stringify(business.value || {}));
  changed.value = false;
}

function editBankAccount() {
  bankDialog.value.open(business.value);
}

function invalidBankAccount(localValue) {
  return !(
    (localValue.bank_routing_number?.length || 0) === 9 &&
    (localValue.bank_account_number?.length || 0) > 6 &&
    localValue.bank_account_name &&
    localValue.bank_account_type
  );
}

async function load() {
  await loadBusiness();
  void loadProfile();
  void loadProviders();
}

async function loadBusiness() {
  const response = await Api.manager.business.get();
  business.value = response.data;
  draft.value = JSON.parse(JSON.stringify(business.value || {}));
}

async function loadProfile() {
  const response = await Api.manager.profile.get();
  profile.value = response.data;
}

async function loadProviders() {
  const response = await Api.manager.provider.index();
  providers.value = response.data;
}

async function updateBankAccount(newValue) {
  processing.value = true;

  const id = null;
  const response = await Api.manager.business.update(id, newValue);

  processing.value = false;
  bankDialogIsVisible.value = false;
  business.value = response.data;
}

async function updateBusiness() {
  const id = null;
  const response = await Api.manager.business.update(id, { name: draft.value.name });
  business.value = response.data;

  changed.value = false;
  eventBus.chime('Saved');
}

onMounted(load);
</script>

<template>
  <v-card
    elevation="0"
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>

      <h2 class="v-card-title py-0 px-0">
        {{ $t(title) }}
      </h2>

      <v-spacer />

      <v-btn
        @click="$refs.newDialog.open()"
        id="manager_add_photo"
        color="primary"
        prepend-icon="add"
        tracked
      >
        <span>{{ $t('Upload') }}</span>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="py-4">
      <v-row>
        <v-col>
          {{ $t(description) }}
        </v-col>
      </v-row>
    </v-card-text>

    <ResourceDialog
      ref="newDialog"
      save-button-text="Done"
      title="Upload"
      close-on-save
    >
      <template #form>
        <dashboard
          :props="uppyProps"
          :uppy="uppy"
        />
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import Uppy from '@uppy/core';
import useUppyUtils from '@/shared/composables/useUppyUtils';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';

const SUPPORTED_VIDEO_TYPES = ['avi', 'mov', 'mp4', 'mpeg', 'ogv', 'webm'];
const SUPPORTED_IMAGE_TYPES = ['heic', 'heif', 'jpg', 'jpeg', 'png', 'svg', 'tif', 'tiff', 'webp'];

const eventBus = useEventBus();
const { locale, t } = useI18n();
const { getUploadAPIEndpoint, getUppyLocale } = useUppyUtils();

const props = defineProps({
  category: {
    type: String,
    default: 'image',
  },
  description: {
    type: String,
    default: 'Make your profile stand out! Add photos of your classrooms and/or facility.',
  },
  providerId: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: 'Photos',
  },
});

const emit = defineEmits(['back', 'load']);

const imageErrorMessage = computed(() => {
  return `This is an unsupported file type. Please only include the following image formats: ${SUPPORTED_IMAGE_TYPES.join(', ')}.`;
});
const videoErrorMessage = computed(() => {
  return `This is an unsupported file type. Please only include the following video formats: ${SUPPORTED_VIDEO_TYPES.join(', ')}.`;
});

const newDialog = ref(null);
const uppy = ref(null);
const uppyProps = ref({
  proudlyDisplayPoweredByUppy: false,
  inline: true,
  height: 200,
  locale: getUppyLocale(),
  width: '100%',
});
watch(
  locale,
  (newLocale, formerLocale) => {
    if (newLocale && formerLocale && formerLocale !== newLocale) {
      window.location.reload();
    }
  },
  { immediate: true },
);

onMounted(uppyInit);

onBeforeUnmount(() => {
  uppy.value.close();
});

function uppyInit() {
  uppy.value = new Uppy().use(XHRUpload, {
    endpoint: getUploadAPIEndpoint(),
  });

  uppy.value.on('file-added', (file) => {
    //  File type values might depend on browser or OS;
    //  without a check for just the extension, correct file type could be accidentally rejected
    const fileFormatted = file.name.split('.').pop().toLowerCase();

    if (props.category === 'image' && !SUPPORTED_IMAGE_TYPES.includes(fileFormatted)) {
      // eslint-disable-next-line no-alert
      alert(t(imageErrorMessage.value));
      uppy.value.removeFile(file.id);
    } else if (props.category === 'video' && !SUPPORTED_VIDEO_TYPES.includes(fileFormatted)) {
      // eslint-disable-next-line no-alert
      alert(t(videoErrorMessage.value));
      uppy.value.removeFile(file.id);
    }
  });

  uppy.value.on('upload-success', (file, evt) => {
    const asset = {
      file: {
        id: evt.body.id, // remove the Shrine storage prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      },
      category: props.category,
    };

    Api.manager.provider.asset.create(
      props.providerId,
      asset,
      () => {
        newDialog.value.close();
        emit('load');
      },
      (error) => eventBus.error(error),
    );
  });
}
</script>

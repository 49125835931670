<template>
  <div>
    <v-container class="mxw-1400">
      <v-card
        border
        tile
      >
        <v-card-title role="heading">Openings & Enrollments</v-card-title>

        <v-card-text>
          <v-tabs
            v-model="tab"
            @update:model-value="tabChange"
            bg-color="surface"
            grow
          >
            <v-tab
              data-testid="openings_tab"
              value="openings"
            >
              Openings
            </v-tab>
            <v-tab
              data-testid="enrollments_tab"
              value="enrollments"
            >
              Enrollments
            </v-tab>
          </v-tabs>

          <v-window
            v-model="tab"
            class="border-t"
          >
            <v-window-item value="openings">
              <ProgramOpenings
                @change:openings-confirmed="confirmAvailability($event)"
                ref="openings"
                :openings-confirmed-at="provider.openings_confirmed_at"
                :provider-id="provider.id"
              />
            </v-window-item>
            <v-window-item value="enrollments">
              <!--Openings Index-->
              <EnrollmentCount
                ref="enrollments"
                :description="meta.enrollment.description"
                :provider-id="provider.id"
              />
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import EnrollmentCount from '@/shared/components/provider/EnrollmentCount.vue';
import ProgramOpenings from '@/shared/components/provider/ProgramOpenings.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const router = useRouter();
const route = useRoute();

const tab = ref('openings');
const meta = ref(null);
const store = useStore();

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

async function load() {
  if (route.query.innerTab) {
    tabChange(route.query.innerTab);
  } else {
    tab.value = 'openings';
  }
  meta.value = store.state.schemas[props.provider.schema_id]?.meta;
}

const confirmAvailability = async (message) => {
  const response = await Api.provider.update(props.provider.id, {
    openings_confirmed: true,
  });
  eventBus.chime(message);
  props.provider = response.data;
};

function tabChange(newValue) {
  tab.value = newValue;
  router.push({
    query: { tab: 'openings_and_enrollments', innerTab: tab.value },
  });
}
onMounted(load);
</script>

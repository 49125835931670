import { useStore } from 'vuex';
import useAssets from '@/shared/composables/useAssets';
import { useI18n } from 'vue-i18n';
import { useRtl } from 'vuetify';
import { onMounted } from 'vue';

/**
 * Helper at the app level for setting language upon application load if url includes
 * lang param.
 *
 * Note: Consider moving unique behavior (onMounted checks) to LocaleSelector
 */
export default function useLanguageHelper() {
  const { assets } = useAssets();
  const store = useStore();
  const { locale } = useI18n();
  const vuetifyRtl = useRtl();

  onMounted(() => {
    const currentUrl = new URL(window.location.href);
    const lang = currentUrl.searchParams.get('lang');
    if (lang) setLang(lang);
  });

  // TODO: Determine if this ever is triggered - LocaleSelector may manager
  //       this such that the check here for mismatched locale settings is never true
  watch(
    () => store.state.default_locale,
    (newVal) => {
      if (newVal && newVal !== locale.value) setLang(newVal);
    },
    { immediate: true },
  );

  function setLang(lang) {
    locale.value = lang || store.state.default_locale || 'en';
    updateRTL();
    updateHtmlLang();
  }

  function updateHtmlLang() {
    document.documentElement.lang = locale.value;
  }

  function updateRTL() {
    vuetifyRtl.value = assets.rightToLeftLanguages.includes(locale.value);
  }
}

<template>
  <v-container
    class="px-0 py-0"
    fluid
  >
    <v-card
      v-if="items"
      class="mt-3 mx-3 bt-1 br-1 bl-1 bc-outlined-gray"
      tile
    >
      <v-card-text class="fs-15 py-4">
        <v-row dense>
          <v-col class="d-flex align-center">
            <p class="mb-0 py-1">
              {{ $t('Displaying') }}
              <strong>{{ items?.length }}</strong> {{ $t('out of') }}
              <strong>{{ totalItemCount }}</strong> {{ $t('total results') }}.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-data-table-server
      v-if="items"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="100"
      :loading="processing"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      item-key="id"
      no-data-text="No statements found"
      fixed-header
      return-object
      show-expand
      show-select
    >
      <template #item.id="{ item }">
        <span>
          {{ item.id?.split('-')[0] }}
        </span>
      </template>

      <template #item.status="{ item }">
        <span v-if="item.status === 'current'">
          {{ $t('Current') }}
        </span>

        <v-chip
          v-if="item.status === 'processing'"
          class="pa-0 fw-500 font-italic"
          color="primary"
          variant="text"
        >
          {{ $t('Processing') }}
        </v-chip>

        <v-chip
          v-else-if="item.status === 'paid'"
          class="pa-0 fw-500"
          color="green"
          variant="text"
        >
          {{ $t('Paid') }}
        </v-chip>

        <div
          v-else-if="item.status === 'past_due'"
          class="pa-0 fw-500"
          color="green"
          variant="text"
        >
          <v-icon
            class="me-1"
            color="red"
            icon="error"
            size="small"
          />

          <v-chip
            class="pa-0 fw-500"
            color="red"
            variant="text"
          >
            {{ $t('Past due') }}
          </v-chip>
        </div>
      </template>

      <template #item.datePaid="{ item }">
        <div
          v-if="showPayButtons && item.status === 'current'"
          class="pa-0 fw-500"
          color="green"
          variant="text"
        >
          <v-btn
            color="primary"
            size="small"
            text="Make a payment"
            variant="outlined"
          />
        </div>

        <div
          v-else-if="showPayButtons && item.status === 'past_due'"
          class="pa-0 fw-500"
          color="green"
          variant="text"
        >
          <v-btn
            color="primary"
            size="small"
            text="Pay now"
            variant="outlined"
          />
        </div>

        <span v-else>
          {{ item.datePaid }}
        </span>
      </template>

      <template #expanded-row="{ item }">
        <template v-if="item.adjustments.length === 0">
          <tr class="bg-white-smoke">
            <td
              class="ta-center"
              colspan="9"
            >
              <span>
                {{ $t('No adjustments') }}
              </span>
            </td>
          </tr>
        </template>

        <template
          v-for="adjustment in item.adjustments"
          v-else
          :key="adjustment.amount.toString() + adjustment.reason.toString()"
        >
          <tr class="bg-white-smoke">
            <td colspan="4" />

            <td
              class="d-flex justify-end align-center"
              colspan="1"
            >
              {{ currency(adjustment.amount) }}
            </td>

            <td colspan="1" />

            <td
              class="justify-end"
              colspan="1"
            >
              {{ adjustment.reason }}
            </td>

            <td colspan="2" />
          </tr>
        </template>
      </template>

      <template #item.adjusted_amount="{ item }">
        <span>
          {{ currency(item.adjusted_amount) }}
        </span>
      </template>

      <template #item.amount_unpaid="{ item }">
        <span>
          {{ currency(item.amount_unpaid) }}
        </span>
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { currency } from '@/plugins/filters';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  filters: {
    type: Array,
    default: () => [],
  },
  showPayButtons: Boolean,
});

const invoiceDates = ref(null);
const invoiceNumbers = ref(null);
const items = ref(null);
const locations = ref([]);
const pastDue = ref(null);
const paymentAdjustments = ref([]);
const processing = ref(null);
const statuses = ref([]);
const totalItemCount = ref(null);
const upToDate = ref(null);

const tableHeaders = computed(() => {
  return [
    {
      title: t('Location'),
      value: 'business_name',
    },
    {
      title: t('Invoice Number'),
      value: 'id',
    },
    {
      title: t('Invoice Date'),
      value: 'scheduled_date',
    },
    {
      title: t('Status'),
      value: 'status',
    },
    {
      title: t('Adjusted Amount'),
      value: 'adjusted_amount',
      align: 'end',
    },
    {
      title: t('Unpaid Balance'),
      value: 'amount_unpaid',
      align: 'end',
    },
  ];
});

async function load() {
  await loadPaymentAdjustments();
  await loadInvoices();

  void loadLocations();
  void loadStatuses();

  if (props.filters.includes('invoiceDates')) void loadInvoiceDates();
  if (props.filters.includes('invoiceNumbers')) void loadInvoiceNumbers();
}

async function loadPaymentAdjustments() {
  const response = await Api.manager.payment_adjustment.index();
  paymentAdjustments.value = response?.data;
}

async function loadInvoices() {
  const response = await Api.manager.payment.index();

  items.value = response?.data;

  // Add adjustments to payments
  items.value.forEach((payment) => {
    payment.adjustments = paymentAdjustments.value.filter(
      (adjustment) => adjustment.payment_id === payment.id,
    );
  });

  totalItemCount.value = items.value.length;

  upToDate.value = true;
  pastDue.value = true;
}

async function loadInvoiceDates() {
  invoiceDates.value = items.value.map((item) => item.invoiceNumber);
}

async function loadInvoiceNumbers() {
  invoiceNumbers.value = items.value.map((item) => item.invoiceNumber);
}

async function loadLocations() {
  locations.value = items.value.map((item) => item.location);
}

async function loadStatuses() {
  statuses.value = ['pending', 'approved', 'unpaid', 'paid', 'canceled'];
}

onMounted(load);
</script>

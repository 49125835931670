<template>
  <div>
    <v-container class="mxw-1400">
      <v-card
        border
        tile
      >
        <v-card-title role="heading">
          {{ $t('Billing Center') }}
        </v-card-title>

        <v-card-text class="px-0 py-0">
          <v-tabs
            v-model="tab"
            @update:model-value="tabChange"
            bg-color="surface"
            grow
          >
            <v-tab value="invoices">
              {{ $t('Invoices') }}
            </v-tab>

            <v-tab value="statements">
              {{ $t('Statements') }}
            </v-tab>
          </v-tabs>

          <v-window
            v-model="tab"
            class="border-t"
          >
            <v-window-item value="invoices">
              <BillingInvoiceIndex
                :filters="['location', 'status']"
                :show-pay-buttons="true"
              />
            </v-window-item>

            <v-window-item value="statements">
              <template v-if="statement">
                <BillingStatementDialog
                  @statement:hide="statement = null"
                  :default-providers="providers"
                  :statement="statement"
                  summary-card-class="bg-super-light-grey"
                />
              </template>

              <template v-else>
                <BillingStatementIndex
                  @statement:show="statement = $event"
                  :can-open-payments="false"
                  :statements-api="Api.manager.billingStatements"
                />
              </template>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import BillingInvoiceIndex from '@/manager/components/BillingInvoiceIndex.vue';
import BillingStatementIndex from '@/shared/components/billing/BillingStatementIndex.vue';
import BillingStatementDialog from '@/shared/components/billing/BillingStatementDialog.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const providers = ref([]);
const statement = ref(null);
const tab = ref('statements');

async function load() {
  if (route.query.tab === 'invoices') tabChange('invoices');

  await loadProviders();
}

async function loadProviders() {
  const response = await Api.manager.provider.index();
  providers.value = response.data;
}

function tabChange(newValue) {
  tab.value = newValue;
  router.push({
    query: { tab: tab.value },
  });
}

onMounted(load);
</script>

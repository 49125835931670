<template>
  <v-card-title class="d-block px-0">
    <div class="px-4">
      <v-row dense>
        <v-col
          class="d-flex align-center lh-24"
          cols="10"
          dense
        >
          <span class="fs-24 fw-600 me-2">{{ enrollment.meta.child.name }}</span>
          <span class="fs-18 fw-500">#{{ enrollment.child_external_id }}</span>
        </v-col>
        <v-col
          class="d-flex justify-end"
          cols="2"
        >
          <v-btn
            @click="$emit('close')"
            :aria-label="$t('Close')"
            variant="text"
            icon
          >
            <v-icon icon="close" />
          </v-btn>
        </v-col>
      </v-row>

      <div class="fs-16">
        <v-row dense>
          <v-col class="lh-22">
            <LongDate
              :date="enrollment.meta.child.dob"
              prefix="Date of birth:"
            />
          </v-col>
        </v-row>
        <template v-if="enrollment.meta.child.gender || enrollment.meta.child.primary_language">
          <v-row dense>
            <v-col class="lh-22">
              <template v-if="enrollment.meta.child.gender">
                <span>{{ $t('Gender') }}</span>
                <span class="me-2">:</span>
                <span>{{ $t(enrollment.meta.child.gender) }}</span>

                <template v-if="enrollment.meta.child.primary_language">
                  <span class="ms-2 me-2">|</span>
                </template>
              </template>

              <template v-if="enrollment.meta.child.primary_language">
                <span>{{ $t('Primary language') }}</span>
                <span class="me-2">:</span>
                <span>{{ $t(enrollment.meta.child.primary_language) }}</span>
              </template>
            </v-col>
          </v-row>
        </template>
        <template v-if="subsidyProgram?.enable_provider_review_viewable_fields">
          <ViewOnlyDataFields
            :excluded-props="displayedFields"
            :schema-id="enrollment.meta.child.schema_id"
            :value-object="enrollment.meta.view_only_child"
            dense
          />
        </template>
      </div>
    </div>

    <v-divider class="mt-4" />

    <div class="px-4 fs-16">
      <v-row
        class="mt-4"
        dense
      >
        <v-col class="lh-22">
          <v-icon
            color="primary"
            icon="place"
            start
          />
          <span>{{ $t(enrollment.meta.provider.name) }}</span>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="lh-22">
          <template v-if="enrolled">
            <v-icon
              color="primary"
              icon="date_range"
              start
            />
            <LongDate
              :date="enrollment.start_date"
              prefix="Enrolled on"
            />
          </template>

          <template v-else>
            <v-icon
              color="primary"
              icon="date_range"
              start
            />
            <LongDate
              :date="enrollment.created_at"
              prefix="Created on"
            />
          </template>
        </v-col>
      </v-row>

      <v-row
        v-if="subsidyProgram?.enable_provider_review && enrollment.subsidy_id"
        dense
      >
        <v-col class="lh-22">
          <v-icon
            color="primary"
            icon="assignment"
            start
          />
          <a
            @click="$refs.subsidyReviewDialog.open(enrollment.subsidy_id, subsidyProgram)"
            class="px-0 underlined cursor-pointer"
          >
            {{ $t('View application') }}
          </a>
        </v-col>
      </v-row>

      <v-row v-if="enrolled">
        <v-col>
          <v-btn
            @click.stop="$emit('edit:program')"
            append-icon="expand_more"
            color="primary"
            size="x-large"
            variant="flat"
            block
            tile
          >
            <span>{{ $t(terms.program) }}</span>
            :
            <span class="ms-2 me-2">
              {{ $t(enrollment.meta.program ? enrollment.meta.program.name : 'Unassigned') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>

      <template v-if="enrolled && fundable">
        <v-row>
          <v-col>
            <v-chip
              v-for="fundingSourceId in enrollment.funding_source_ids"
              @click="$emit('edit:fundingSources')"
              :key="fundingSourceId"
              class="me-3"
            >
              {{
                $t(fundingSources.find((fundingSource) => fundingSource.id == fundingSourceId).name)
              }}
            </v-chip>
            <v-chip
              @click="$emit('edit:fundingSources')"
              color="primary"
            >
              <v-icon
                icon="add"
                start
              />
              <span>{{ $t('Add funding source') }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </template>

      <v-row
        v-if="!enrolled"
        class="mt-4"
        dense
      >
        <v-col>
          <v-btn
            @click="$emit('edit:status')"
            append-icon="expand_more"
            color="primary"
            size="x-large"
            variant="outlined"
            block
            tile
          >
            <span>{{ $t('Status') }}: {{ $t(getStatusText(enrollment.status)) }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        v-if="enrollment.status_updated_at"
        dense
      >
        <v-col class="lh-22 pt-2 fs-14">
          <LongDate
            :date="enrollment.status_updated_at"
            prefix="Status last changed on"
          />
        </v-col>
      </v-row>
    </div>

    <SubsidyReviewDialog ref="subsidyReviewDialog" />
  </v-card-title>
</template>

<script setup>
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import LongDate from '@/shared/components/LongDate.vue';
import SubsidyReviewDialog from '@/manager/components/SubsidyReviewDialog.vue';
import ViewOnlyDataFields from '@/shared/components/ViewOnlyDataFields.vue';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';
import useTerms from '@/shared/composables/useTerms';

const DISPLAYED_FIELDS = [
  'created_at',
  'dob',
  'external_id',
  'first_name',
  'group_id',
  'gender',
  'id',
  'last_name',
  'middle_name',
  'name',
  'primary_language',
  'schema_id',
  'updated_at',
];

const { terms } = useTerms();
const { getStatusText } = useEnrollmentStatuses();

const props = defineProps({
  enrollment: {
    type: Object,
    default: null,
  },
  fundingSources: {
    type: Array,
    default: () => [],
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const displayedFields = ref(DISPLAYED_FIELDS);

defineEmits(['close', 'edit:fundingSources', 'edit:program', 'edit:status']);

const enrolled = computed(() => {
  return props.enrollment.status === ENROLLMENT_STATUSES.ENROLLED;
});

const fundable = computed(() => {
  return props.subsidyProgram?.enable_enrollment_funding_sources && props.fundingSources.length > 0;
});
</script>

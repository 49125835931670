import { createRouter, createWebHistory } from 'vue-router';
import store from '@/manager/store';

import CodeSent from '@/shared/views/CodeSent.vue';
import FallbackRouter from '@/shared/views/FallbackRouter.vue';
import WebpageLanding from '@/shared/views/WebpageLanding.vue';
import LoginCodes from '@/shared/components/LoginCodes.vue';
import PublicProviderShow from '@/public/views/ProviderShow.vue';
import RegisterFactor from '@/shared/views/RegisterFactor.vue';
import SignOut from '@/shared/views/SignOut.vue';
import ContactSubscriptions from '@/shared/views/ContactSubscriptions.vue';
import WebpageShow from '@/shared/views/WebpageShow.vue';
import AssessmentIndex from '@/manager/views/AssessmentIndex.vue';
import AssessmentShow from '@/manager/views/AssessmentShow.vue';
import AttendanceIndex from '@/manager/views/AttendanceIndex.vue';
import BillingCenterIndex from '@/manager/views/BillingCenterIndex.vue';
import BusinessShow from '@/manager/views/BusinessShow.vue';
import CourseIndex from '@/manager/views/CourseIndex.vue';
import CourseShow from '@/manager/views/CourseShow.vue';
import DocumentIndex from '@/manager/views/DocumentIndex.vue';
import EnrollmentIndex from '@/manager/views/EnrollmentIndex.vue';
import FormNew from '@/manager/views/forms/FormNew.vue';
import FormRequestIndex from '@/manager/views/forms/FormRequestIndex.vue';
import GrantNew from '@/manager/views/grants/GrantNew.vue';
import GrantProgramIndex from '@/manager/views/grants/GrantProgramIndex.vue';
import GrantProgramShow from '@/manager/views/grants/GrantProgramShow.vue';
import InterestedIndex from '@/manager/views/InterestedIndex.vue';
import LessonQuiz from '@/manager/views/LessonQuiz.vue';
import LessonShow from '@/manager/views/LessonShow.vue';
import ManagerDashboard from '@/manager/views/managers/ManagerDashboard.vue';
import ManagerProfile from '@/manager/views/managers/ManagerProfile.vue';
import ManagerWelcome from '@/manager/views/managers/ManagerWelcome.vue';
import MassMessageIndex from '@/manager/views/MassMessageIndex.vue';
import MessageIndex from '@/manager/views/MessageIndex.vue';
import OnboardingForm from '@/manager/views/OnboardingForm.vue';
import PaymentIndex from '@/manager/views/PaymentIndex.vue';
import ProviderIndex from '@/manager/views/ProviderIndex.vue';
import ProviderShow from '@/manager/views/ProviderShow.vue';
import RegisterAccess from '@/manager/views/RegisterAccess.vue';
import RegisterName from '@/manager/views/RegisterName.vue';
import RegisterPassword from '@/shared/views/RegisterPassword.vue';
import StaffIndex from '@/manager/views/StaffIndex.vue';
import StudentIndex from '@/manager/views/StudentIndex.vue';
import TermsAgreement from '@/manager/views/TermsAgreement.vue';
import TourShow from '@/manager/views/TourShow.vue';

export const routes = [
  {
    component: ManagerWelcome,
    name: 'Welcome',
    path: '/welcome',
    meta: {
      depth: 1,
      public: true,
      registration: true,
      title: 'Welcome',
    },
  },

  {
    component: AssessmentIndex,
    name: 'AssessmentIndex',
    path: '/assessments',
    meta: {
      depth: 2,
      title: 'QRIS',
    },
  },

  {
    component: RegisterAccess,
    name: 'RegisterAccess',
    path: '/access',
    meta: {
      depth: 2,
      title: 'Join your organization',
    },
    beforeEnter(to, from, next) {
      !store.state.profile.business_id ? next() : next('/');
    },
  },

  {
    component: RegisterFactor,
    name: 'RegisterFactor',
    path: '/mfa',
    meta: {
      depth: 2,
      registration: true,
      title: 'Register MFA',
    },
  },

  {
    component: RegisterName,
    name: 'RegisterName',
    path: '/register',
    meta: {
      depth: 2,
      registration: true,
      title: 'RegisterName',
    },
  },

  {
    component: RegisterPassword,
    name: 'RegisterPassword',
    path: '/password',
    meta: {
      depth: 2,
      registration: true,
      title: 'Set password',
    },
  },

  {
    component: OnboardingForm,
    name: 'OnboardingForm',
    path: '/onboarding/:schemaId',
    meta: {
      depth: 3,
      registration: true,
      title: 'Onboarding',
    },
  },

  {
    component: AssessmentShow,
    name: 'AssessmentShow',
    path: '/assessments/:assessmentId/edit',
    meta: {
      depth: 3,
    },
  },

  {
    component: BillingCenterIndex,
    name: 'BillingCenterIndex',
    path: '/billing_center',
  },

  {
    component: CodeSent,
    meta: { background: 'bg-super-light-blue', public: true },
    name: 'CodeSent',
    path: '/code_sent',
    props: (route) => ({ routeParams: route.params, routeQuery: route.query }),
  },

  {
    component: CourseIndex,
    name: 'CourseIndex',
    path: '/courses',
    meta: {
      depth: 2,
      title: 'Learning',
    },
  },

  {
    component: CourseShow,
    name: 'CourseShow',
    path: '/courses/:courseId',
    meta: {
      depth: 3,
      title: 'Learning',
    },
  },

  {
    component: DocumentIndex,
    name: 'DocumentIndex',
    path: '/documents',
    meta: {
      depth: 2,
      title: 'Documents',
    },
  },

  {
    component: GrantProgramIndex,
    name: 'GrantProgramIndex',
    path: '/grants',
    meta: {
      depth: 2,
      title: 'Grants',
    },
  },

  {
    component: GrantNew,
    name: 'GrantNew',
    path: '/grants/:grantId/new',
    meta: {
      depth: 3,
      hideSideNav: true,
    },
  },

  {
    component: GrantProgramShow,
    name: 'GrantProgramShow',
    path: '/grants/:grantProgramId/manage',
    meta: {
      depth: 3,
      hideSideNav: true,
    },
  },

  {
    component: FormNew,
    name: 'FormNew',
    path: '/providers/:providerId/forms/:schemaId',
    meta: {
      depth: 4,
    },
  },

  {
    component: FormRequestIndex,
    name: 'FormRequestIndex',
    path: '/providers/requests',
    meta: {
      depth: 2,
      title: 'Requests',
    },
    beforeEnter(to, from, next) {
      store.getters.hasProviderRequestableFormSchemas() ? next() : next('/');
    },
  },

  {
    component: InterestedIndex,
    name: 'InterestedIndex',
    path: '/interested/list',
    meta: {
      depth: 2,
      title: 'Interested',
    },
  },

  {
    component: LessonShow,
    name: 'LessonShow',
    path: '/courses/:courseId/lessons/:lessonId',
    meta: {
      depth: 4,
      title: 'Continue',
    },
  },

  {
    component: LessonQuiz,
    name: 'LessonQuiz',
    path: '/lesson_completion/:lessonCompletionId/quizzes/:quizSchemaId',
    meta: {
      depth: 5,
      title: 'Quiz',
    },
  },

  {
    component: ManagerDashboard,
    name: 'Dashboard',
    path: '/dashboard',
    meta: {
      depth: 1,
    },
  },

  {
    component: ManagerProfile,
    meta: { depth: 3 },
    name: 'Profile',
    path: '/member/profile',
  },

  {
    component: MassMessageIndex,
    name: 'MassMessageIndex',
    path: '/mass_messages',
    meta: {
      depth: 2,
      title: 'Mass messages',
    },
  },

  {
    component: MessageIndex,
    name: 'MessageIndex',
    path: '/messages',
    meta: {
      depth: 2,
      title: 'Messages',
    },
  },

  {
    component: ProviderIndex,
    name: 'ProviderIndex',
    path: '/providers/list',
    meta: {
      depth: 2,
      title: 'My locations',
    },
    beforeEnter(to, from, next) {
      store.state.pages.Provider.enabled ? next() : next('/');
    },
  },

  {
    component: ProviderShow,
    name: 'ProviderShow',
    path: '/providers/:providerId/edit',
    meta: {
      depth: 3,
    },
  },

  {
    path: '/manager',
    redirect: '/welcome',
  },

  {
    component: LoginCodes,
    meta: { depth: 1, public: true, title: 'Login' },
    name: 'LoginCode',
    path: '/login_codes/:login_code',
  },

  {
    component: PublicProviderShow,
    meta: { depth: 2, public: true },
    name: 'ProviderCrawlerShow',
    path: '/providers/:city/:zip/:name',
  },

  {
    component: PublicProviderShow,
    meta: { depth: 2, public: true },
    name: 'PublicProviderShow',
    path: '/providers/:providerId',
  },

  {
    component: BusinessShow,
    meta: {
      app_bar: true,
      depth: 10,
      title: 'BusinessShow',
    },
    name: 'BusinessShow',
    path: '/business/settings',
    beforeEnter(_to, _from, next) {
      store.state.profile?.business_role === 'Admin' ? next() : next('/');
    },
  },

  {
    path: '/settings',
    redirect: '/business/settings',
  },

  {
    component: StaffIndex,
    meta: {
      app_bar: true,
      depth: 10,
      title: 'StaffIndex',
    },
    name: 'StaffIndex',
    path: '/business/staff',
    beforeEnter(_to, _from, next) {
      store.state.profile?.business_role === 'Admin' ? next() : next('/');
    },
  },

  {
    path: '/staff',
    redirect: '/business/staff',
  },

  {
    component: StudentIndex,
    meta: { depth: 3 },
    path: '/students',
    children: [
      {
        component: EnrollmentIndex,
        name: 'EnrollmentIndex',
        meta: { depth: 4 },
        path: '',
      },
      {
        meta: { depth: 5 },
        name: 'PaymentIndex',
        path: 'payments',
        component: PaymentIndex,
      },
      {
        meta: { depth: 5 },
        name: 'AttendanceIndex',
        path: 'attendance',
        component: AttendanceIndex,
      },
    ],
  },

  {
    component: SignOut,
    meta: {
      app_bar: true,
      depth: 10,
      title: 'Sign out',
    },
    name: 'SignOut',
    path: '/sign_out',
  },

  {
    component: ContactSubscriptions,
    name: 'Subscriptions',
    path: '/subscriptions',
    meta: { depth: 999, public: true, title: 'Subscriptions' },
  },

  {
    component: TermsAgreement,
    meta: {
      registration: true,
      title: 'Terms agreement',
    },
    name: 'TermsAgreement',
    path: '/terms_agreement',
  },

  {
    component: TourShow,
    name: 'TourShow',
    path: '/tours/:tourId',
    meta: {
      depth: 3,
      title: 'Tours',
    },
  },

  {
    component: WebpageShow,
    meta: {
      background: 'bg-white',
      public: true,
    },
    name: 'WebpageShow',
    path: '/pages/:webpageId',
  },

  {
    component: WebpageLanding,
    meta: {
      public: true,
    },
    name: 'Landing',
    path: '/',
  },

  {
    path: '/:pathMatch(.*)*',
    component: FallbackRouter,
    meta: {
      public: true,
    },
  },
];

export default createRouter({
  history: createWebHistory(import.meta.env.VUE_BASE_URL),
  routes,
});

<template>
  <v-row :dense="dense">
    <LabeledTextfield
      v-for="standardProp in standardProps"
      :key="standardProp"
      :field="standardProp"
      :model-value="humanizeBoolean(valueObject[standardProp])"
      :schema-id="schemaId"
      cols="12"
      md="4"
      dense
    />
    <LabeledTextfield
      v-for="customProp in customProps"
      :key="customProp"
      :hard-lock="true"
      :message="schema?.definition?.properties?.custom?.properties[customProp]?.alias"
      :model-value="humanizeBoolean(valueObject.custom[customProp])"
      cols="12"
      md="4"
      dense
    />
  </v-row>
</template>

<script setup>
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { t } = useI18n();

const props = defineProps({
  dense: {
    type: Boolean,
    default: false,
  },
  valueObject: {
    type: Object,
    default: () => {},
  },
  excludedProps: {
    type: Array,
    default: () => [],
  },
  schemaId: {
    type: String,
    default: null,
  },
});

const customProps = computed(() => {
  if (!props.valueObject) return [];

  const { custom } = props.valueObject;
  return Object.keys(custom);
});

const standardProps = computed(() => {
  if (!props.valueObject) return [];

  const keys = Object.keys(props.valueObject);
  return keys.filter((key) => !props.excludedProps?.concat('custom').includes(key));
});

const schema = computed(() => {
  return store.state.schemas[props.schemaId];
});

function humanizeBoolean(answer) {
  if (answer === true) return t('Yes');
  if (answer === false) return t('No');
  return answer;
}
</script>
